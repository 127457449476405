
import {
  Component, Vue, Prop,
} from "vue-property-decorator";
import { Action } from "vuex-class";
import { Team } from "@/model/team.model";
import { TeamMembership } from "@/model/team-membership.model";
import { ObjectService } from "@/services/object.service";
import { getTeamMembershipsModuleNamespace, getTeamRolesModuleNamespace } from "@/store/store.utils";
import { TeamRole } from "@/model/team-role";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

@Component({})
export default class EditMembershipDialog extends Vue {
  open = false;

  loading = false;

  rolesLoading = false;

  defaultMembership: TeamMembership | null = null;

  membership: TeamMembership | null = null;

  allRoles: TeamRole[] = [];

  @Prop()
  pTeam!: Team;

  @Action("loadAllRoles", getTeamRolesModuleNamespace())
  loadAllRoles!: () => Promise<TeamRole[]>

  @Action("assignRole", getTeamMembershipsModuleNamespace())
  assignRole!: (data: { teamMembershipId: string; roleId: string }) => Promise<void>

  @Action("revokeRole", getTeamMembershipsModuleNamespace())
  revokeRole!: (data: { teamMembershipId: string; roleId: string }) => Promise<void>

  @Action("reloadPage", getTeamMembershipsModuleNamespace())
  reloadPage!: () => Promise<void>

  get disableSave() {
    return this.loading || ObjectService.isEqual(this.defaultMembership, this.membership);
  }

  openDialog(membership: TeamMembership) {
    this.loadRoles();
    this.defaultMembership = membership;
    this.membership = this.defaultMembership.copy();
    this.open = true;
  }

  async loadRoles() {
    this.rolesLoading = true;
    try {
      this.allRoles = await this.loadAllRoles();
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
    this.rolesLoading = false;
  }

  async save() {
    if (!this.membership || !this.defaultMembership) {
      throw Error("Can't save membership");
    }

    this.loading = true;

    const addedRoles = this.membership.roles.filter(role => (
      !this.defaultMembership!.roles.some(it => it.id === role.id)
    ));
    const revokedRoles = this.defaultMembership.roles.filter(role => (
      !this.membership!.roles.some(it => it.id === role.id)
    ));

    try {
      await Promise.all([
        ...addedRoles.map(role => this.assignRole({ teamMembershipId: this.membership!.id, roleId: role.id })),
        ...revokedRoles.map(role => this.revokeRole({ teamMembershipId: this.membership!.id, roleId: role.id })),
      ]);

      Vue.toasted.success(`${this.membership.user.fullName} Membership successfully updated!`, {
        duration: 3000,
      });

      this.reloadPage();

      this.open = false;
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }

    this.loading = false;
  }
}
