<template>
  <v-card>
    <v-data-table
        v-if="pSelectedTeam"
        class="team-memberships-table"
        :headers="headers"
        :items="displayTeamMemberships"
        :loading="currentPageLoading"
        :server-items-length="totalCount"
        @update:options="paginate($event)"
        :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            Memberships
            <EditMembershipDialog ref="editMembershipDialog" />
            <ConfirmationDialog ref="confirmationDialog" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <div class="team-membership-roles">
          <div v-if="!item.roles.length">No Roles</div>
          <div v-else class="d-flex flex-wrap align-center">
            <div v-for="role in item.roles" :key="role.id">
              <v-tooltip top open-delay="300" content-class="team-membership-role-info-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-1"
                  >{{role.name}}</v-chip>
                </template>
                <v-container class="w-100 d-flex align-center">
                  <v-row class="w-100">
                    <v-col>
                      <div class="w-100 d-flex justify-center mb-2"><span class="mr-1" v-if="!role.entityPermissions.length">No</span>Entity Permissions</div>
                      <div class="mb-1" v-for="(it, i) in role.entityPermissions" :key="i">
                        <EntityPermissionsChip :pEntityPermission="it"/>
                      </div>
                    </v-col>
                    <v-col>
                      <div class="w-100 d-flex justify-center mb-2"><span class="mr-1" v-if="!role.actions.length">No</span>Actions</div>
                      <div class="mb-1" v-for="(action, i) in item.actions" :key="i">
                        <v-chip>{{action.text}}</v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="edit-roles-btn"
          x-small
          :disabled="disableEditMembership"
          @click="openEditMembershipDialog(item)"
        >Edit roles</v-btn>
        <v-icon
          medium
          class="ml-2 delete-membership-btn"
          color="red"
          @click="openDeleteMembershipDialog(item)"
          :disabled="disableEditMembership"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import {
  dismissAction,
  logAndExtractMessage,
  paginateTable,
} from "@/utils/utils";
import EditMembershipDialog from "@/components/team-page/team-memberships/EditMembershipDialog";
import EntityPermissionsChip from "@/components/team-roles/EntityPermissionsChip";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";

export default {
  name: "TeamMemberships",

  components: {
    ConfirmationDialog,
    EntityPermissionsChip,
    EditMembershipDialog,
  },

  props: {
    pSelectedTeam: Object,
  },

  data: () => ({

    localStorageTableSizeKey: "team.memberships.table.size",

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      {
        text: "First Name",
        value: "user.firstName",
        sortable: false,
      },
      {
        text: "Last Name",
        value: "user.lastName",
        sortable: false,
      },
      { text: "Roles", value: "roles", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("teamsModule", ["teamStats"]),
    ...mapState("userModule", ["user"]),
    ...mapState("teamMembershipsModule", [
      "currentPageLoading",
      "currentPage",
      "displayTeamMemberships",
      "totalCount",
    ]),

    isUserTeamManager() {
      return this.teamStats.manager?.id === this.user.id;
    },

    disableEditMembership() {
      return !this.isUserTeamManager
        && !this.user.isUserAdminHost(this.pSelectedTeam.host)
        && !this.user.isSuperAdmin;
    },
  },

  methods: {

    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("teamMembershipsModule/loadPage", {
          ...currentPage,
          teamId: this.pSelectedTeam.id,
        });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openEditMembershipDialog(membership) {
      this.$refs.editMembershipDialog.openDialog(membership);
    },

    openDeleteMembershipDialog(membership) {
      this.$refs.confirmationDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete membership of "${membership.user.firstName} ${membership.user.lastName}"?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("teamMembershipsModule/deleteMembership", membership);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.team-membership-role-info-tooltip {
  .row {
    width: 100%;
    min-width: 350px;
  }
}
</style>
